import { fetchGet, fetchPost, fetchPut } from './index'

// offer_type/sssc/search
// 检索 SSSC
export interface PgSsscProps {
    tableCell: any;
    tableCol: any;
}
export const ssscSearch = (params = {}): Promise<PgSsscProps> => {
    return fetchGet('/pgapi/offer_type/sssc/search', params)
}