
import { defineComponent, ref, onMounted, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { message, Modal } from 'ant-design-vue'
import { FileInfo, ColumnsProps, AutoTableColProps } from '@/types'
import blobDownload from '@/utils/blobDownload'
import { ssscSearch, PgSsscProps } from '@/API/sssc'
import { getSubscribedBu } from "@/API/approve"
import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import { baseURL } from '@/API'
import Pagination from "@/components/Pagination.vue";
import useFetch from "@/hooks/useFetch";
import useTableHeight from "@/hooks/useTableHeight";
export default defineComponent({
    components: { Pagination },
    setup () {
        const store = useStore()
        const dataSoure = ref([])
        const visible = ref<boolean>(false)
        const columns = ref<ColumnsProps[]>([])
        const buSelectData = ref<BuItem[]>([])
        const visibleModal = ref<boolean>(false)
        const errorMsg = ref<string>('')
        const errorURL = ref<string>('')
        const { tableHeight } = useTableHeight();
         const queryParams = reactive<{
            bu: string;
            'model_name': string;
            baumuster: string;
        }>({
                bu: store.state.user.bu.orgid,
                'model_name': '',
                baumuster: ''
            })

        const fileList = ref([])

        // 导出excel
        const handleExport = () => {
            const params = {
                url: '/pgapi/offer_type/sssc/export',
                params: queryParams
            }
            blobDownload(params)
        }
        // 导出excel
        const handleExportAll = () => {
            const params = {
                url: '/pgapi/offer_type/sssc/export',
                params: Object.assign(queryParams, { 'export-all': true })
            }
            blobDownload(params)
        }
        const getColumns = (data: PgSsscProps) => {
            columns.value = []
            data.tableCol.forEach((item: AutoTableColProps, index: number) => {
                const params: ColumnsProps = {
                    title: item.display,
                    dataIndex: item.id,
                    key: item.id,
                }
                columns.value.push(params)
            })
        }
        // 获取business unit
        const getBuUnit = () => {
            getSubscribedBu().then(res => {
                buSelectData.value = res
            })
        }

        const { pagination, refreshFetch } = useFetch<any>(Object.assign(queryParams, {page: 1, pageSize: 10}), [], ssscSearch, true, (res: any) => {
            dataSoure.value = res.tableCell
            getColumns(res)
        });

        //分页事件处理
        const handlerPaginationChange = (page: number): void => {
            pagination.page = page;
            refreshFetch();
        }

        const handlerShowSizeChange = (current: number, pageSize: number): void => {
            pagination.pageSize = pageSize;
            Object.assign(pagination, { page: current, pageSize: pageSize });
            refreshFetch();
        }

        const handleSearch = () => {
            refreshFetch(1)
        }

        const headers = {
            Authorization: store.state.Authorization
        }
        // 关闭上传弹窗，需要刷新列表
        const handleClose = () => {
            visible.value = false
            fileList.value = []
            refreshFetch()
        }
        const handleUpload = (info: FileInfo) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                if ((info.file.response as any).data && (info.file.response as any).data.description && (info.file.response as any).data.message !== 'File upload successfully!') {
                    visibleModal.value = true
                    errorMsg.value = (info.file.response as any).data.message
                    errorURL.value = (info.file.response as any).data.downloadUrl
              } else {
                // 上传文件成功
                Modal.info({
                    title: 'Tip',
                    content: `File upload successfully`,
                    onOk() {
                        handleClose()
                    }
                });
              }
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }

        }
        onMounted(() => {
            getBuUnit()
        })
        return {
            buSelectData,
            dataSoure,
            columns,
            visible,
            fileList,
            refreshFetch,
            handleClose,
            handleUpload,
            handleExportAll,
            handleExport,
            headers,
            baseURL,
            visibleModal,
            errorMsg,
            errorURL,
            handlerPaginationChange,
            handlerShowSizeChange,
            pagination,
            tableHeight,
            handleSearch,
            ...toRefs(queryParams)
        }
    }
})
